import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
  root: {
    background: "linear-gradient(to bottom, black, blue 20%, black 80%)",
    padding: "2rem",
  },
  title: {
    color: "white",
    textDecoration: "underline",
    marginBottom: "2rem",
  },
  witchContainer: {
    position: "relative",
  },
  witchTxt: {
    position: "absolute",
    top: "30%",
    right: "1%",
  },
  satomiContainer: {
    position: "relative",
  },
  satomiWithHat: {
    position: "absolute",
    top: "-10%",
    left: "5%",
  },
  satomiTxt: {
    position: "absolute",
    width: "100%",
    top: "40%",
    right: "1%",
  },
  msgTxt: {
    color: "white",
    marginBottom: "1.2rem",
    marginTop: "1.2rem",
    fontSize: "1.8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.6rem",
    },
  },
  strongText1: {
    fontSize: "2.7rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.9rem",
    },
  },
  strongText2: {
    fontFamily:
      "Hiragino Maru Gothic Pro, Hiragino Maru Gothic ProN, Meiryo, sans-serif",
    fontSize: "2.7rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.9rem",
    },
  },
}))

const MessageSection = () => {
  const classes = useStyles()
  const breakpoints = useBreakpoint()

  return (
    <div className={classes.root}>
      <Typography
        variant={
          breakpoints.xs
            ? "h4"
            : breakpoints.sm
            ? "h3"
            : breakpoints.md
            ? "h2"
            : "h2"
        }
        align="center"
        className={classes.title}
      >
        お伝えしたいこと
      </Typography>
      <Grid
        container
        spacing={2}
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: "100rem",
        }}
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            src="../../images/random_txt.png"
            alt="カラーメニュー"
            placeholder="blurred"
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          justifyContent="center"
          alignContent="center"
        >
          <Grid item>
            <Typography
              variant="body1"
              className={classes.msgTxt}
              align="center"
            >
              カラーの名称は
              <span className={classes.strongText1}>
                <strong>
                  <u>後付け</u>
                </strong>
              </span>
              です。
              <br />
              大切なことは、ただ一つ・・・
            </Typography>
            <Typography
              variant="body1"
              align="center"
              className={classes.strongText2}
              style={{ color: "yellow" }}
            >
              <strong>
                「こういうカラーに
                <br />
                したいんだ！」
              </strong>
            </Typography>
            <Typography
              variant="body1"
              className={classes.msgTxt}
              align="center"
            >
              それをぜひ教えてください！
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "2rem",
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: "100rem",
        }}
      >
        <Grid item xs={12} sm={6}>
          <StaticImage
            src="../../images/witch_3_promise.png"
            alt="3つのお約束"
            placeholder="blurred"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StaticImage
            src="../../images/question.png"
            alt="質問"
            placeholder="blurred"
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default MessageSection
