import React, { useEffect, useState } from "react"
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import InstagramIcon from "../../../assets/Instagram_icon-icons.com_66804.svg"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  title: {
    color: "white",
    marginBottom: "2rem",
  },
}))

const InstragramImages = () => {
  const classes = useStyles()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true)
    }, 3000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.title}
      >
        <Grid item xs={5}>
          <Typography
            variant="body1"
            align="right"
            style={{
              fontSize: "2rem",
              textDecoration: "underline",
              fontFamily: "ヒラギノ丸ゴ ProN W4",
            }}
          >
            <a
              href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              ギャラリー
            </a>
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="center" alignItems="center">
          <Grid item>
            <InstagramIcon style={{ width: "3rem", height: "3rem" }} />
          </Grid>
        </Grid>
        <Grid item xs={5} align="left">
          <Typography
            variant="body1"
            style={{
              fontSize: "2rem",
              textDecoration: "underline",
              color: "cyan",
            }}
          >
            <a
              href="https://www.instagram.com/color_of_magician.bh/?hl=ja"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go Instagram
            </a>
          </Typography>
        </Grid>
      </Grid>
      {mounted ? (
        <div className="elfsight-app-42fe86bd-9e8f-4b37-9529-d6dd39a381a8"></div>
      ) : (
        <Box align="center">
          <CircularProgress />
        </Box>
      )}
    </div>
  )
}

export default InstragramImages
