import React from "react"
import { makeStyles } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import { isMobile } from "react-device-detect"

import Seo from "../components/SEO"
import Layout from "../components/Layout"
import InstagramImages from "../components/InstagramImages"
import MessageSection from "../components/MessageSection"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "black",
  },
}))

const HomePage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title="Home/ホーム" />
      <div className={classes.root}>
        {isMobile ? (
          <StaticImage
            src="../images/sp_top_kv.jpg"
            alt="top_kv"
            placeholder="blurred"
            layout="fullWidth"
          />
        ) : (
          <StaticImage
            src="../images/pc_top_kv.jpg"
            alt="top_kv"
            placeholder="blurred"
            layout="fullWidth"
          />
        )}
        <InstagramImages />
        <Divider
          style={{
            color: "white",
            backgroundColor: "white",
            width: "100%",
          }}
        />
        <MessageSection />
      </div>
    </Layout>
  )
}

export default HomePage
